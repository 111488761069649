import React, { useEffect } from 'react'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import exhibitorsLogo from '../assets/svg/ss/exhibition.svg'
import profileLogo from '../assets/svg/ss/profile.svg'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import EnhancedTableToolbar from './enhancedToolbar'
import axios from 'axios'
import config from '../config.json'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Image, { Shimmer } from 'react-shimmer'
import Utils from './utils'
import PropTypes from 'prop-types'

const Joi = require('joi')

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'slno', numeric: true, disablePadding: true, label: 'SL NO' },
		{ id: 'name', numeric: false, disablePadding: false, label: 'User Name' },
		{ id: 'email', numeric: false, disablePadding: false, label: 'Coordinates' },
		{ id: 'roles', numeric: false, disablePadding: false, label: 'Roles' },
		{ id: 'action', numeric: false, disablePadding: false, label: 'Actions' }
	]
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

const exampleWrapperStyle = {
	position: 'absolute',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}
const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		overflow: 'auto',
		justifyContent: 'center',
		height: '100%',
		'@media (max-height: 420px)': {
			alignItems: 'flex-start'
		}
	},
	root: {
		width: 'calc(100%)'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
		paddingLeft: 20,
		paddingRight: 20
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	grid: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		marginBottom: '10px'
	},
	total: {
		textAlign: 'left'
	},
	totalDetails: {
		marginRight: '20px'
	},
	totalAttendes: {
		display: 'flex',
		alignItems: 'baseline',
		width: '30%',
		// marginRight: '80px'
		// borderRight: "1px solid #CACACA",
		'@media (max-width: 600px)': {
			width: '300px'
		}
	},
	paper2: {
		backgroundColor: '#f2f2f2',
		boxShadow: theme.shadows[5],
		// padding: theme.spacing(1.5, 1),
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		outline: 'none',
		padding: '10px 20px',
		width: '500px',
		borderRadius: '10px',
		overflow: 'auto',
		margin: '5px',
		'@media (max-width: 420px)': {
			padding: '10px 5px'
		}
	},
	feedbackText: {
		height: '120px',
		width: '250px',
		borderRadius: '10px',
		margin: '5px 0px 10px'
	},
	submit: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '10px'
	},
	h3: {
		textAlign: 'center'
		// margin: 0,
	},
	inputFields: {
		marginTop: 10
	},
	card: {
		// margin: '0px 30px',
		position: 'relative'
	},
	profileImage: {
		position: 'absolute',
		right: '20px',
		top: '20px'
	},
	margin: {
		marginLeft: '8px'
	},
	pos: {
		display: 'flex',
		alignItems: 'center',
		padding: '2px 5px',
		'@media (max-width: 420px)': {
			fontSize: '14px',
			fontWeight: '400'
		}
	},
	title: {
		textAlign: 'left',
		fontWeight: '800'
	},
	flex: {
		display: 'flex',
		alignItems: 'center'
	},
	button: {
		background: 'white',
		textTransform: 'capitalize'
	}
}))

export default function SelectProjectManager(props) {
	const classes = useStyles()

	const [showError, setShowError] = React.useState(false)
	const [emailID, setEmailID] = React.useState('')
	const [password, setPassword] = React.useState('')

	const [emailError, setEmailError] = React.useState(null)
	const [passwordError, setPasswordError] = React.useState(null)
	const [contactingServer, setContactingServer] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)

	const [rows, setRows] = React.useState([])

	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState([])
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [dense] = React.useState(false)

	const pageLimits = [10, 25, 50]
	let offset = 0

	async function getList() {
		try {
			let url = config['baseurl'] + '/api/user/list' + '?role=projectManager,deputyManager'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			//   console.log(data);
			//   console.log(data.count);
			//   setTotalCount(data.count);
			//   console.log(data.users);
			let newRows = []
			for (let i = 0; i < data.count; ++i) {
				// console.log("getList: 1");
				newRows.push(Utils.createData(i + 1, data.users[i]))
				// console.log("getList: 2");
			}

			setRows(newRows)
		} catch (e) {
			console.log('Error in getting users list')
			setErrorMessage('Error in getting users list')
			setShowError(true)
		}
	}

	useEffect(() => {
		getList()

		console.log('props.project.managers: ', props.project.managers)
		console.log(props.project.managers.includes('5fb0132560fe267207c565c6'))
	}, [])

	const validateData = () => {
		const schema = Joi.object({
			emailID: Joi.string()
				.email({ minDomainSegments: 2, tlds: { allow: false } })
				.required(),
			password: Joi.string()
				.min(6)
				.max(100)
				.required()
		})
		const { error } = schema.validate(
			{
				emailID: emailID.trim(),
				password: password.trim()
			},
			{ abortEarly: false }
		)
		const allerrors = {}
		if (error) {
			for (let item of error.details) allerrors[item.path[0]] = item.message
		}

		return allerrors
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rows.map(n => n.name)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const isSelected = name => selected.indexOf(name) !== -1

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const getStringForArray = data => {
		let val = ''
		for (let i = 0; i < data.length; ++i) {
			if (data[i] === 'projectManager' || data[i] === 'deputyManager') {
				if (val.length > 0) val += ', '
				val += data[i]
			}
		}
		return val
	}

	const handleSelect = async userdata => {
		console.log('handleEdit: ', userdata)

		setContactingServer(true)
		try {
			let url = config['baseurl'] + '/api/project/addmanager'

			let postObj = {}
			postObj['_id'] = props.project._id
			postObj['manager'] = userdata._id

			console.log('postObj: ', postObj)

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const updated = await axios.patch(url, postObj)
			console.log(updated)

			console.log('successfully Saved')
			setContactingServer(false)
			props.selected()
		} catch (e) {
			if (e.response) {
				console.log('Error in creating new user')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in creating new user')
				setErrorMessage('Error in creating new user: ', e.message)
			}
			setShowError(true)
			setContactingServer(false)
		}

		// props.setSelectedUser(userdata);
		// props.history.push("/edituser");
	}

	const isProjectManagerValid = id => {
		for (let i = 0; i < props.project.managers.length; ++i) {
			if (props.project.managers[i] == id) return false
		}

		return true
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		offset = 0
	}

	return (
		<div style={exampleWrapperStyle}>
			<Modal aria-labelledby='transition-modal-title' aria-describedby='transition-modal-description' className={classes.modal} open={true}>
				<Fade in={true}>
					<div style={{ margin: 10, height: '80%', overflow: 'auto' }}>
						<Paper className={classes.grid}>
							{/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
							<TableContainer>
								<Table
									className={classes.table}
									aria-labelledby='tableTitle'
									size={dense ? 'small' : 'medium'}
									aria-label='enhanced table'
								>
									<EnhancedTableHead
										classes={classes}
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={rows.length}
									/>

									<TableBody>
										{stableSort(rows, getComparator(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => {
												const isItemSelected = isSelected(row.name)
												const labelId = `enhanced-table-checkbox-${index}`
												return (
													<TableRow hover tabIndex={-1} key={row.slno}>
														<TableCell component='th' id={labelId} scope='row' padding='none'>
															{row.slno}
														</TableCell>
														<TableCell>
															<div className={classes.flex}>
																<Image
																	src={row.logo_url ? row.logo_url : profileLogo}
																	NativeImgProps={{ className: classes.exhibitor_image, width: 40, height: 40 }}
																	style={{ objectFit: 'cover' }}
																	fallback={<Shimmer width={50} height={50} />}
																/>

																<span>{row.data.name}</span>
															</div>
														</TableCell>
														<TableCell>
															<span>{row.data.phone}</span>
															<br></br>
															<span>{row.data.email}</span>
														</TableCell>
														<TableCell>
															<span>{getStringForArray(row.data.role)}</span>
														</TableCell>
														<TableCell>
															<div>
																{isProjectManagerValid(row.data._id) && (
																	<Button
																		onClick={() => handleSelect(row.data)}
																		style={{ background: '#314293', color: '#FFFFFF' }}
																		variant='contained'
																		className={classes.button}
																	>
																		{'Select'}
																	</Button>
																)}
															</div>
														</TableCell>
													</TableRow>
												)
											})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={pageLimits}
								component='div'
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
							<div className={classes.submit}>
								<Button variant='contained' color='primary' onClick={props.close} disabled={contactingServer}>
									Cancel
								</Button>
							</div>

							{/* <TablePagination rowsPerPageOptions={pageLimits} component="div" count={totalCount} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} /> */}
						</Paper>
					</div>
				</Fade>
			</Modal>
			<Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>
		</div>
	)
}
